import React, {useEffect, useRef} from 'react'

export type useRunOnceProps = {
  fn: () => any
  sessionKey?: string | any
  isLoggedOut?: boolean
}

const useRunOnce: React.FC<useRunOnceProps> = ({fn, sessionKey, isLoggedOut}) => {
  const triggered = useRef<boolean>(false)

  useEffect(() => {
    const hasBeenTriggered = sessionKey
      ? Boolean(sessionStorage.getItem(sessionKey))
      : triggered.current

    if (!hasBeenTriggered) {
      fn()
      triggered.current = true

      if (sessionKey && !isLoggedOut) {
        sessionStorage.setItem(sessionKey, 'true')
      }
    }
  }, [fn, isLoggedOut, sessionKey])

  return null
}

export default useRunOnce
