import {array, boolean, number, object, string} from 'yup'

export const contactValidationSchema = object().shape({
  contactFirstName: string().required('First Name is required'),
  contactLastName: string().required('Last Name is required'),
  contactRole: string().required('Role is required'),
  contactEmail: string()
    .email('Invalid email format')
    .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
    .test('no-consecutive-dots', 'Invalid email format', (value: any) => !value?.includes('..'))
    .max(255, 'Email is too long'),
  contactPhone: string()
    .test('valid-phone-number', 'Invalid phone number format', (value) => {
      if (!value) {
        return true
      }
      return /^\d{10,11}$/.test(value)
    })
    .test('numeric-characters-only', 'Invalid phone number format', (value) => {
      if (!value) {
        return true
      }
      return /^\d+$/.test(value)
    }),
})

export const userValidationSchema = object().shape({
  firstName: string().required('First Name is required'),
  lastName: string().required('Last Name is required'),
  role: string().required('Role is required'),
  alias: string().required('Alias is required'),
  emailAddress: string()
    .required('Email is required')
    .email('Invalid email format')
    .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
    .test('no-consecutive-dots', 'Invalid email format', (value: any) => !value?.includes('..'))
    .max(255, 'Email is too long'),
})

export const companyContacts = object().shape({
  contacts: array().of(
    object().shape({
      contactFirstName: string().required('Contact name is required'),
      contactLastName: string().required('Contact surname is required'),
      contactRole: string().required('Contact Role is required'),
      contactEmail: string()
        .email('Invalid email format')
        .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
        .test('no-consecutive-dots', 'Invalid email format', (value: any) => !value?.includes('..'))
        .max(255, 'Email is too long'),
    })
  ),
})

export const sites = object().shape({
  postcode: string().required('Postcode is required'),
  siteName: string().required('Site name is required'),
  siteAddress: string().required('Site address is required'),
  phoneNumber: string()
    .matches(/^\d{10,11}$/, 'Invalid phone number format')
    .test('numeric-characters-only', 'Invalid phone number format', (value: any) => {
      if (!value) {
        return true
      }
      return /^\d+$/.test(value)
    }),
})

export const companySites = array().of(sites)

export const companyValidationSchema = object().shape({
  companyDetails: object().shape({
    companyType: number().required('Company type is required'),
    companyName: string().required('Company name is required'),
    postcode: string().required('Postcode is required'),
    physicalAddress: string().required('Physical Address is required'),
    email: string()
      .email('Invalid email format')
      .test('no-consecutive-dots', 'Invalid email format', (value: any) => !value?.includes('..'))
      .matches(/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/, 'Invalid email format')
      .max(255, 'Email is too long')
      .required('Email is required'),
    phone: string()
      .matches(/^\d{10,11}$/, 'Invalid phone number format')
      .test('numeric-characters-only', 'Invalid phone number format', (value: any) =>
        /^\d+$/.test(value)
      )
      .required('Phone number is required'),
    jayexAccountManager: string(),
    existingClient: boolean(),
  }),
  companyContacts,
  sites: companySites,
})

export const companyInitialFormValues = {
  companyDetails: {
    companyName: '',
    companyType: '',
    companyAlias: '',
    practiceNumber: '',
    xeroRefNumber: '',
    email: '',
    phone: '',
    postcode: '',
    physicalAddress: '',
    website: '',
    businessRegistrationNumber: '',
    linkedCompanies: [],
    jayexAccountManager: '',
    existingClient: false,
    companyTags: '',
    pastCrmId: '',
    oldCRMLink: '',
  },
  companyContacts: {
    contacts: [
      {
        contactFirstName: '',
        contactLastName: '',
        contactRole: '',
        contactPhone: '',
        contactEmail: '',
        isPrimaryContact: false,
      },
    ],
  },
  sites: [
    {
      postcode: '',
      siteName: '',
      siteAddress: '',
    },
  ],
}
