import {useContext, FC} from 'react'
import {Link, useLocation} from 'react-router-dom'
import Stack from 'react-bootstrap/Stack'
import {Navbar, Nav, Container, NavDropdown, Button} from 'react-bootstrap'
import {Bell, ThreeDotsVertical, Person, BoxArrowRight} from 'react-bootstrap-icons'
import {StyledClickable} from 'styles/styled'
import {Config} from 'contexts/config'
import RoutePaths from 'enums/routes'
import {translate} from 'i18n'
import Logo from 'assets/images/logo.png'
import Avatar from 'components/Avatar'
import useAuthentication from 'hooks/useAuthentication'

const HeaderRightContent: FC = () => {
  const {getUser} = useContext(Config)
  const {firstName = '', lastName = '', email = ''} = getUser() ?? {}
  const {isAdmin, logout} = useAuthentication()

  return (
    <Stack direction="horizontal" gap={4} className="headerIcon">
      <StyledClickable>
        <ThreeDotsVertical />
      </StyledClickable>
      <StyledClickable>
        <Bell />
      </StyledClickable>
      <NavDropdown
        className="no-caret"
        title={<Avatar firstName={firstName} lastName={lastName} />}
      >
        <NavDropdown.Header>
          <div className="pad15">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              title={`${firstName} ${lastName}`}
              email={email}
            />
          </div>
        </NavDropdown.Header>
        <NavDropdown.Divider />
        <NavDropdown.Item as={Link} to="/admin" hidden={!isAdmin}>
          <Person />
          <span style={{verticalAlign: 'middle', marginLeft: '6px'}}>Admin</span>
        </NavDropdown.Item>
        <NavDropdown.Item as={Button} onClick={() => logout()}>
          <BoxArrowRight />
          <span style={{verticalAlign: 'middle', marginLeft: '6px'}}>Logout</span>
        </NavDropdown.Item>
      </NavDropdown>
    </Stack>
  )
}

const PrimaryNavigation = ({
  isActiveNavLink,
  pathname,
}: {
  isActiveNavLink: (arg: string) => boolean
  pathname: string
}) => {
  return (
    <Navbar expand="md">
      <Container>
        <Navbar.Brand href="/">
          <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navigation" />
        <Navbar.Collapse className="justify-content-end" id="main-navigation">
          <Nav activeKey={pathname} className="mr-auto">
            <Nav.Link as={Link} to="/dashboard" active={pathname === RoutePaths.HOME}>
              {translate('menu.dashboards')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={RoutePaths.CONTACTS}
              active={isActiveNavLink(RoutePaths.CONTACTS)}
            >
              {translate('menu.contacts')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={RoutePaths.COMPANIES}
              active={isActiveNavLink(RoutePaths.COMPANIES)}
            >
              {translate('menu.companies')}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <HeaderRightContent />
      </Container>
    </Navbar>
  )
}

const SecondaryNavigation = ({
  isActiveNavLink,
  pathname,
}: {
  isActiveNavLink: (arg: string) => boolean
  pathname: string
}) => {
  return (
    <Navbar expand="md">
      <Container>
        <Navbar.Brand href="/">
          <img alt="Logo" src={Logo} height="30" className="d-inline-block align-top" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="main-navigation" />
        <Navbar.Collapse className="justify-content-end" id="main-navigation">
          <Nav activeKey={pathname} className="mr-auto">
            <Nav.Link as={Link} to={RoutePaths.SALES} active={pathname === RoutePaths.SALES}>
              {translate('menu.sales')}
            </Nav.Link>
            <Nav.Link as={Link} to={RoutePaths.INSTALLS} active={pathname === RoutePaths.INSTALLS}>
              {translate('menu.installs')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={RoutePaths.OPPORTUNITIES}
              active={pathname === RoutePaths.OPPORTUNITIES}
            >
              {translate('menu.opportunities')}
            </Nav.Link>
            <Nav.Link as={Link} to={RoutePaths.PRODUCTS} active={pathname === RoutePaths.PRODUCTS}>
              {translate('menu.products')}
            </Nav.Link>
            <Nav.Link as={Link} to={RoutePaths.TASKS} active={pathname === RoutePaths.TASKS}>
              {translate('menu.tasks')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={RoutePaths.ACCOUNTING}
              active={isActiveNavLink(RoutePaths.ACCOUNTING)}
            >
              {translate('menu.accounting')}
            </Nav.Link>
            <Nav.Link
              as={Link}
              to={RoutePaths.CONTACTS}
              active={isActiveNavLink(RoutePaths.CONTACTS)}
            >
              {translate('menu.contacts')}
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>

        <HeaderRightContent />
      </Container>
    </Navbar>
  )
}

export default function Navigation() {
  const location = useLocation()

  const isSecondaryNavigation = locationPathnames.includes(location.pathname)

  const isActiveNavLink = (navLink: string) => {
    return location.pathname.includes(navLink)
  }

  return isSecondaryNavigation ? (
    <SecondaryNavigation isActiveNavLink={isActiveNavLink} pathname={location.pathname} />
  ) : (
    <PrimaryNavigation isActiveNavLink={isActiveNavLink} pathname={location.pathname} />
  )
}

const locationPathnames = [
  '/companies/overview',
  '/companies/notes',
  '/companies/emails',
  '/companies/calls',
  '/companies/tasks',
  '/companies/meetings',
  '/companies/solutions',
]
