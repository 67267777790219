import React, {ChangeEvent, FocusEvent, useEffect} from 'react'
import Form from 'react-bootstrap/Form'
import RequiredFieldLabel from 'components/RequiredFieldLabel'
import AsyncSelect from 'react-select'
import enumHelper from 'utils/enum'
import Permissions, {PermissionsType} from 'enums/permissions'

interface FormControl {
  controlId: string
  label: string
  type: string
  name: string
}

interface CreateAdminFormProps {
  values: any
  touched: Record<string, boolean>
  errors: any
  validateForm?: any
  roles?: any
  permissions?: any
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void
  setFieldValue: any
  isEdit: boolean
}

const CreateAdminForm: React.FC<CreateAdminFormProps> = ({
  values,
  touched,
  errors,
  isEdit,
  roles,
  permissions,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  const onHandleChange = (selectedOptions: any) => {
    setFieldValue('role', selectedOptions?.value)
  }

  const renderSelect = (control: FormControl) => {
    if (control.name === 'permissions') {
      return isEdit ? (
        <div
          style={{padding: '20px', border: '1px solid #222', height: '300px', overflowY: 'auto'}}
          className="mb-3"
        >
          {permissions?.map((permission: PermissionsType, index: string) => (
            <Form.Check
              key={index}
              label={enumHelper.getValueForKey(permission, Permissions)}
              name={control.name}
              reverse
              type={'checkbox'}
              id={`permission-${index}`}
              style={{textAlign: 'left'}}
              checked={values?.[control.name]?.includes(permission)}
              onChange={handleChange}
              value={permission}
            />
          ))}
        </div>
      ) : null
    } else {
      return (
        <>
          <AsyncSelect
            name={control.name}
            value={{label: values?.[control.name], value: values?.[control.name]}}
            options={roles?.map((role: string) => ({
              label: role,
              value: role,
            }))}
            onChange={onHandleChange}
            onBlur={handleBlur}
          />

          <div className="invalid-feedback error">
            {touched?.['react-select-3-input'] && errors?.[control.name]}
          </div>
        </>
      )
    }
  }

  useEffect(() => {
    if (!isEdit && values?.firstName && values?.lastName) {
      // if (!values?.alias) {
      setFieldValue('alias', `${values?.firstName} ${values?.lastName}`)
      // }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, values?.firstName, values?.lastName])

  return (
    <div>
      {formControlData.map((control: FormControl) => (
        <Form.Group
          key={control.controlId}
          className="mb-3 address-field"
          controlId={control.controlId}
        >
          {control.name === 'permissions' && !isEdit ? null : (
            <Form.Label>
              <RequiredFieldLabel label={control.label} />
            </Form.Label>
          )}

          {control.type === 'select' ? (
            renderSelect(control)
          ) : (
            <>
              <Form.Control
                type={control.type}
                name={control.name}
                value={values?.[control.name]}
                onChange={handleChange}
                onBlur={handleBlur}
                isValid={touched?.[control.name] && !errors?.[control.name]}
                isInvalid={touched?.[control.name] && errors?.[control.name]}
                autoFocus={control.name === 'firstName'}
              />
              <div className="invalid-feedback error">
                {touched?.[control.name] && errors?.[control.name]}
              </div>
            </>
          )}
        </Form.Group>
      ))}
    </div>
  )
}

const formControlData: FormControl[] = [
  {controlId: 'firstName', label: 'First Name', type: 'text', name: 'firstName'},
  {controlId: 'lastName', label: 'Last Name', type: 'text', name: 'lastName'},
  {controlId: 'alias', label: 'Alias', type: 'text', name: 'alias'},
  {controlId: 'emailAddress', label: 'Email Address', type: 'text', name: 'emailAddress'},
  {controlId: 'role', label: 'User Role', type: 'select', name: 'role'},
  {controlId: 'permissions', label: 'Permissions', type: 'select', name: 'permissions'},
]

export default CreateAdminForm
