import {Container, Row, Col, Button} from 'react-bootstrap'
import logo from 'assets/images/logo.svg'
import {useEffect} from 'react'

export default function LoggedOut() {
  useEffect(() => {
    sessionStorage.removeItem('checkAuthentication')
  }, [])

  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <Row className="justify-content-center">
        <Col xs={12} className="text-center">
          <img src={logo} alt="Logo" style={{width: '100%'}} />
        </Col>
        <Col xs={12} className="mt-4 text-center">
          <h2>Succesfully Logged Out</h2>
        </Col>
        <Col xs={12} className="mt-4 text-center">
          <Button variant="primary" href="/">
            Login
          </Button>
        </Col>
      </Row>
    </Container>
  )
}
