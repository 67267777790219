import {useEffect, useState, ChangeEvent, useMemo} from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {StyledSearchInput, StyledSubTitle, StyledTitle} from 'styles/styled'
import {translate} from 'i18n'
import {adminColumnsDef} from 'grids/adminColumnsDef'
import DataTable from 'components/DataTable'
import ReusableModal from 'components/ReusableModal'
import useAuthentication from 'hooks/useAuthentication'
import {useDispatch, useSelector} from 'react-redux'
import AdminForm from 'forms/AdminForm'
import {Stack} from 'react-bootstrap'
import {getUsers, getRoles, getUser} from 'reduxStore/actions/authActions'
import {selectUsers, selectRoles} from 'reduxStore/selectors/authSelectors'

export default function AdminPanel() {
  const [searchText, setSearchText] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const [userEditData, setUserEditData] = useState<any>({})
  const [selectedRole, setSelectedRole] = useState('all')
  const {token} = useAuthentication()

  const dispatch = useDispatch()
  const users = useSelector(selectUsers)
  const roles = useSelector(selectRoles)

  useEffect(() => {
    if (token) {
      dispatch(getUsers(token))
    }
  }, [dispatch, token])

  useEffect(() => {
    if (token) {
      dispatch(getRoles(token))
    }
  }, [dispatch, token])

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const filteredbyRole = useMemo(() => {
    if (selectedRole === 'all') return users
    return users.filter((item: any) => item?.role === selectedRole.toLowerCase())
  }, [users, selectedRole])

  const filteredData: any = filteredbyRole?.filter((item: any) =>
    Object.values(item).some(
      (value) => value && value.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  )

  const handleClose = () => {
    setIsModalOpen(false)
    setIsEdit(false)
  }

  const handleDownloadCSV = (data: any) => {
    console.log('download')
  }

  const handleRowClicked = async (data: any) => {
    await dispatch(getUser({token, userId: data?.id}))
    setIsEdit(true)
    setIsModalOpen(true)
    setUserEditData(data)
  }

  const handleRolesChange = (e: any) => {
    setSelectedRole(e.target.value)
  }

  return (
    <>
      <Container className="p-5">
        <Row className="align-items-center justify-content-between">
          <Col md={6}>
            <Stack>
              <StyledSubTitle>{translate('admin.title')}</StyledSubTitle>
              <StyledTitle>{translate('admin.users')}</StyledTitle>
            </Stack>
          </Col>
          <Col md={6} className="text-end">
            <Button variant="primary" onClick={() => setIsModalOpen(true)}>
              {translate('admin.addUser')}
            </Button>
            <Button variant="link" onClick={handleDownloadCSV}>
              {translate('admin.downloadCSV')}
            </Button>
          </Col>
        </Row>
        <Row className="align-items-center justify-content-between mt-3">
          <Col md={4}>
            <Form>
              <StyledSearchInput
                className="mb-2"
                placeholder={translate('admin.search')}
                onChange={handleSearch}
              />
            </Form>
          </Col>
          <Col md={8}>
            <Col md={4}>
              <Form.Select
                aria-label="Default select example"
                className="mb-2"
                onChange={handleRolesChange}
              >
                <option value="all">All</option>
                {roles?.map((role: string) => (
                  <option key={role} value={role}>
                    {role}
                  </option>
                ))}
              </Form.Select>
            </Col>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <DataTable
              data={filteredData}
              columns={adminColumnsDef}
              onRowClicked={handleRowClicked}
              responsive
            />
          </Col>
        </Row>
      </Container>

      <ReusableModal
        showModal={isModalOpen}
        handleClose={handleClose}
        modalTitle={isEdit ? 'Edit user' : 'Add user'}
        showFooter={false}
        modalBody={
          <AdminForm userEditData={userEditData} isEdit={isEdit} handleClose={handleClose} />
        }
      />
    </>
  )
}
