import Badge from 'react-bootstrap/Badge'
import {translate} from 'i18n'
import {formatDate} from 'utils/dateTime'

const sharedColumnProps = {
  minWidth: '100px',
}

export const adminColumnsDef: any[] = [
  {
    name: translate('grid.admin.firstName'),
    selector: (row: any) => row.firstName,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.lastName'),
    selector: (row: any) => row.lastName,
    sortable: true,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.email'),
    selector: (row: any) => row.emailAddress,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.alias'),
    selector: (row: any) => row.alias,
    ...sharedColumnProps,
  },
  {
    name: translate('grid.admin.userRole'),
    minWidth: '150px',
    cell: (row: any) => <UserRolesWrapper row={row} />,
  },
  {
    name: translate('grid.admin.lastActive'),
    selector: (row: any) => formatDate(row.lastActiveDate),
    sortable: true,
    ...sharedColumnProps,
  },
]

const UserRolesWrapper = ({row}: any) => (
  <div
    style={{
      padding: '10px',
    }}
  >
    <Badge bg="secondary" className="me-1">
      {row.role}
    </Badge>
  </div>
)
