import {Route, useLocation, Routes, Navigate} from 'react-router-dom'
import CompanyRoutes from './companyRoutes'
import ContactRoutes from './contactRoutes'
import SiteRoutes from './siteRoutes'
import RoutePaths from 'enums/routes'
import Admin from 'pages/admin'
import Dashboard from 'pages/dashboard'
import LoggedOut from 'pages/shared/LoggedOut'

export default function AppRouter() {
  const location = useLocation()

  return (
    <Routes location={location} key={location.pathname}>
      <Route path={RoutePaths.HOME} element={<Navigate to={`${RoutePaths.COMPANIES}/`} />} />
      <Route path="/logged-out" element={<LoggedOut />} />
      <Route path="/admin" element={<Admin />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path={`${RoutePaths.CONTACTS}/*`} element={<ContactRoutes />} />
      <Route path={`${RoutePaths.COMPANIES}/*`} element={<CompanyRoutes />} />
      <Route path={`${RoutePaths.SITES}/*`} element={<SiteRoutes />} />
    </Routes>
  )
}
