import {useEffect} from 'react'
import Button from 'react-bootstrap/Button'
import {translate} from 'i18n'
import useAuthentication from 'hooks/useAuthentication'
import {Formik, Form as FormikForm} from 'formik'
import {userValidationSchema} from 'forms/validations/companyValidationSchema'
import {useDispatch, useSelector} from 'react-redux'
import CreateAdminForm from './CreateAdminForm'
import {
  getUser,
  getPermissions,
  getUsers,
  addUser,
  updatedUser,
} from 'reduxStore/actions/authActions'
import {selectRoles, selectUser, selectPermissions} from 'reduxStore/selectors/authSelectors'

interface AdminFormValues {
  id?: string | number
  firstName: string
  lastName: string
  alias: string
  emailAddress: string
  role: string
  permissions?: string[]
  dateCreated?: string
}

const AdminForm = (props: any) => {
  const {token} = useAuthentication()
  const dispatch = useDispatch()
  const {userEditData, isEdit, handleClose} = props

  const roles = useSelector(selectRoles)
  const user = useSelector(selectUser)
  const permissions = useSelector(selectPermissions)

  useEffect(() => {
    if (token && isEdit) {
      dispatch(getPermissions(token))
    }
  }, [dispatch, isEdit, token])

  return (
    <Formik
      enableReinitialize={true}
      initialValues={isEdit ? user : initialModalFormState}
      validationSchema={userValidationSchema}
      onSubmit={async (values: AdminFormValues, actions) => {
        if (isEdit) {
          await dispatch(updatedUser({token, userId: userEditData?.id, data: values}))
        } else {
          delete values.permissions
          await dispatch(addUser({token, data: values}))
        }

        handleClose?.()

        await dispatch(getUsers(token))

        actions.resetForm()
      }}
    >
      {({handleBlur, handleChange, values, touched, errors, validateForm, setFieldValue}: any) => (
        <FormikForm>
          <CreateAdminForm
            values={values}
            handleChange={handleChange}
            handleBlur={handleBlur}
            touched={touched}
            errors={errors}
            validateForm={validateForm}
            setFieldValue={setFieldValue}
            roles={roles}
            permissions={permissions}
            isEdit={isEdit}
          />
          <div className="d-flex justify-content-between gap-2 mt-5">
            <div className="d-flex gap-2">
              <Button type="submit" variant="primary">
                {isEdit ? translate('global.update') : translate('global.create')}
              </Button>
              <Button variant="outline-primary" onClick={handleClose}>
                {translate('global.cancel')}
              </Button>
            </div>
          </div>
        </FormikForm>
      )}
    </Formik>
  )
}

const initialModalFormState: AdminFormValues = {
  firstName: '',
  lastName: '',
  alias: '',
  emailAddress: '',
  role: '',
  permissions: [],
}

export default AdminForm
