/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useContext, useEffect, useState} from 'react'
import {useNavigate, useLocation} from 'react-router-dom'
import {decodeToken} from 'react-jwt'
import {Config} from 'contexts/config'
import authService from 'services/auth/authService'
import useRunOnce from './useRunOnce'

const useAuthentication = () => {
  const {setUser, getUser, clearUser, isLoggedOut, msalRedirectUrl} = useContext(Config)
  const navigate = useNavigate()
  const location = useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAdmin, setIsAdmin] = useState<boolean | null>(false)
  const {token = ''} = getUser() ?? {}

  const handleAuthorizationCode = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search)
    const authorizationCode = urlParams.get('code')

    if (authorizationCode) {
      try {
        const response = await authService.acquireTokenByCode({
          code: authorizationCode,
          redirectUri: msalRedirectUrl,
        })

        if (response?.token) {
          setUser(response)
        }

        navigate(location.pathname)
      } catch (error) {
        console.log('Token acquisition error', error)
      }
    }
  }, [msalRedirectUrl, setUser])

  const checkAuthentication = useCallback(async () => {
    try {
      const response = await authService.isAuthenticated(token)
      setIsAuthenticated(response)
    } catch (error) {
      console.log('Authentication check error', error)
    }
  }, [token])

  const logout = () => {
    clearUser()
    navigate('/logged-out')
  }

  useEffect(() => {
    if (token) {
      const decoded = decodeToken<any>(token)
      if (decoded?.role?.length > 0) {
        setIsAdmin(decoded.role.includes('admin'))
      }
    }
  }, [token])

  useEffect(() => {
    handleAuthorizationCode()
  }, [handleAuthorizationCode])

  useRunOnce({
    fn: () => {
      checkAuthentication()
    },
    sessionKey: 'checkAuthentication',
    isLoggedOut,
  })

  return {isAuthenticated, isAdmin, token, isLoggedOut, logout}
}

export default useAuthentication
